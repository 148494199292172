import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

// Determine whether the code is running on the server or client
const isServer = typeof window === 'undefined';

// Configure Bugsnag once for both environments
export const configureBugsnag = () => {
  if (process.env.NODE_ENV !== 'production') {
    return null; // Only initialize Bugsnag in production
  }

  if (Bugsnag.isStarted()) {
    // Bugsnag already initialized, return the existing instance
    return Bugsnag;
  }

  // Bugsnag configuration options
  const config = {
    appType: isServer ? 'node' : 'web', // Specify appType based on environment
    appVersion: process.env.NEXT_BUILD_ID,
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || '00000000000000000000000000000000',
    releaseStage: process.env.NEXT_PUBLIC_APP_ENV,
    enabledReleaseStages: ['testing', 'acceptance', 'production'],
    plugins: isServer ? [] : [new BugsnagPluginReact(React)], // Only load React plugin on the client
    onError: (event: { errors: { errorMessage: string }[] }) => {
      if (event.errors[0]?.errorMessage === 'Network Error') {
        return false; // Discard network errors
      }
    },
  };

  // Start Bugsnag with the configuration
  return Bugsnag.start(config);
};

export const BugsnagClient = configureBugsnag();

const formatMessage = (message: string) => `[${process.env.NEXT_PUBLIC_APP_ENV}] ${message}`;

export const notifyError = (err: unknown, fallbackMessage = 'Onverwachte fout') => {
  let error: Error;
  if (err instanceof Error) {
    error = err;
    error.message = formatMessage(err.message);
  } else if (typeof err === 'string') {
    error = new Error(formatMessage(err));
  } else {
    error = new Error(formatMessage(fallbackMessage));
  }

  if (!BugsnagClient) return console.error(error);

  return BugsnagClient?.notify(error, (event) => {
    if (event.errors[0]) {
      event.errors[0].errorClass = error.name;
      event.errors[0].errorMessage = `[${fallbackMessage}]: ${error.message}`;
    }
  });
};
