/* eslint-disable no-unused-vars */

import React from 'react';

export enum FieldTypes {
  rich,
  text,
  input,
  media,
  buttons,
  features,
  products,
  accordion,
}

export enum BlockNames {
  features,
  infoBlocks,
  products,
  richContent,
  cta,
  transportCostCalculator,
  accordion,
}

export interface ButtonValue {
  children?: string;
  href?: string;
  target?: '_blank' | '_self';
  variant?:
    | 'default'
    | 'secondary'
    | 'light'
    | 'destructive'
    | 'outline'
    | 'shadow'
    | 'ghost'
    | 'link';
  rounded?: 'default' | 'sm' | 'md';
  size?: 'default' | 'lg';
  withArrow?: boolean;
}

export interface FeatureValue {
  title: string;
  subtitle: string;
  description: string;
  button: ButtonValue;
  media: MediaValue | null;
}

export interface MediaValue {
  url: string;
  width: number;
  height: number;
  filename: string;
  filepath?: string;
  id?: number;
}

export interface AccordionValue {
  title: string;
  body: string;
}

export interface InfoBlockItem {
  title: string;
  description: string;
  icon: string;
}

export interface InfoBlock {
  subtitle: string;
  title: string;
  description: string;
  item1_title: string;
  item1_description: string;
  item2_title: string;
  item2_description: string;
  item3_title: string;
  item3_description: string;
}

interface StringField {
  type: FieldTypes.input | FieldTypes.text | FieldTypes.rich;
  value: string;
}

interface ButtonsField {
  type: FieldTypes.buttons;
  value: ButtonValue[];
}

interface ProductsField {
  type: FieldTypes.products;
  value: number[];
}

interface FeaturesField {
  type: FieldTypes.features;
  value: FeatureValue[];
}

interface AccordionField {
  type: FieldTypes.accordion;
  value: AccordionValue[];
}

interface MediaField {
  type: FieldTypes.media;
  value: MediaValue | null;
}

export type SharedFieldTypes =
  | ButtonsField
  | FeaturesField
  | ProductsField
  | StringField
  | AccordionField
  | MediaField;

export type BlockFields = Record<
  string,
  SharedFieldTypes & { label: string; showOnVariants?: string[] }
>;

export type BlockType = {
  style?: React.CSSProperties;
  innerStyle?: React.CSSProperties;
  label?: string;
  padding?: string;
  description?: string;
  previewurl?: string;
  id: string;
  uid: string;
  maxWidth?: string;
  name: BlockNames;
  fields: BlockFields;
};
